/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React from 'react';
import { object } from 'prop-types';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import ReactHtmlParser from 'react-html-parser';
import { Grid } from '@material-ui/core';

const styles = (theme) => ({
    container: {
        padding: '20px 10%',
        textAlign: 'center',
    },
    header: {
        color: `${theme.palette.error.main}`,
        textAlign: 'center',
    },
    message: {
        padding: '20px 0px',
        color: `${theme.palette.error.main}`,
    },
});

const outageText = {
    header: 'We are currently conducting some planned maintenance.',
    message: 'Order status, delivery notifications and order modifications will be unavailable during this time. We are still delivering smiles as scheduled and will resume our notifications shortly. Thank you for your patience!',
};

const OutageMessage = ({ classes }) => (
    <Grid className={classes.container} container>
        <Grid xs={12}>
            <Typography
                variant="h6"
                className={classes.header}
                align="center"
            >
                {ReactHtmlParser(outageText.header)}
            </Typography>
        </Grid>
        <Grid xs={12}>
            <Typography
                className={classes.message}
            >
                {ReactHtmlParser(outageText.message)}
            </Typography>
        </Grid>
    </Grid>
);

OutageMessage.propTypes = {
    classes: object.isRequired,
};

export default withStyles(styles)(OutageMessage);

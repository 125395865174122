/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React, { useEffect } from 'react';
import { object, func, string } from 'prop-types';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { trackEvent as track } from '../../../../../state/ducks/TagManager/ducks/TagManager/TagManager-Actions';
import useClickStreamCustomEventsTracking from '../../../../helpers/Personalization/useClickstreamCustomEventsTracking';
import processCss from '../../../../helpers/contentstack/generateValidCss';
import openInNewTab from '../../../../helpers/contentstack/openInNewTab';
import Panel2 from '../../Panel';
import OutageMessage from '../../../GraphqlComponents/CustomerService/Partials/OutageMessage/OutageMessage';
import { getFeatureFlags, getPresentationFamily } from '../../../../../state/ducks/App/ducks/Config/Config-Selectors';
import { openPassportSideBar } from '../../../../../state/ducks/Passport/Passport-Action';
import normalizeAltCopy from '../../../../helpers/normalizeAltCopy';

const useStyles = makeStyles((theme) => ({
    root: ({ bannerStyle }) => ({
        position: 'relative',
        boxSizing: 'border-box',
        display: 'block',
        width: bannerStyle?.width || '100%',
        margin: '0 auto',
        maxWidth: bannerStyle?.maxWidth,
        textDecoration: 'none',
        backgroundPosition: 'center center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: bannerStyle?.backgroundSize || 'cover',
        color: theme.palette.common.black, // TODO: Font color should come from CMS
        backgroundImage: bannerStyle?.backgroundImage,
    }
    ),
    flowerGenericBanner: ({ panel }) => {
        const [imageStyle] = panel.container_attributes?.filter((value) => value?.dimensions);
        const unit = imageStyle?.dimensions?.unit === 'Pixels' ? 'px' : '%';
        const breakPoint = unit === 'px' ? imageStyle?.dimensions?.width : 600;
        return ({
            height: 'auto !important',
            [theme.breakpoints.up(breakPoint)]: {
                width: imageStyle?.dimensions?.width ? `${imageStyle.dimensions.width}${unit} !important` : '100% !important',
            },
            [theme.breakpoints.down(breakPoint)]: {
                width: '100% !important',
            },
        });
    },
    genericBannerSize: {
        '@media screen and (max-width: 1200px)': {
            width: '100% !important',
        },
    },
    headline: {
        margin: `0 auto ${theme.spacing(3)}px auto`,
        padding: '10x 20px',
        textAlign: 'center',
        fontSize: '34px',
        color: theme.palette.common.black, // TODO: Font color should come from CMS
    },
    headlineVideo: {
        textAlign: 'center',
        position: 'absolute',
        margin: '0 auto',
        top: '50%',
        right: '50%',
        transform: 'translate(50%, -50%)',
    },
    ctaLink: {
        textDecoration: 'none',
    },
    cta: {
        boxSizing: 'border-box',
        display: 'block',
        margin: '0 auto',
        fontSize: '18px',
        fontWeight: '800',
        borderRadius: '2px',
    },
    contentSection: ({ banner, panel }) => ({
        margin: '0 auto',
        textAlign: 'center',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPositionX: banner.dimensions?.background_image_position || 'center',
        ...processCss(panel.container_attributes, true),
    }),
    contentSectionVideo: {
        width: '100%',
        height: 'auto',
        margin: '0 auto',
    },
    float: {
        marginLeft: '0px',
    },
    invisibleImage: {
        visibility: 'hidden',
        width: '100%',
    },
}));

const GenericBanner = ({
    data, trackEvent, featureFlags, passportOpenSideBar, presentationFamily, salesforceResponse, setShowSkeleton,
}) => {
    const banner = data?.reference?.[0];
    const panel = banner?.reference?.[0];
    const panelData = {
        message_collection: panel?.message_collection,
        overlays: panel?.overlays,
    };
    if (!banner || !panel) {
        return null;
    }

    useEffect(() => {
        setShowSkeleton(false);
    }, []);

    if (!banner || !panel) {
        return null;
    }
    const unit = banner.dimensions?.unit === 'Percent' ? '%' : 'px';
    const useNewDimensionsIfAvailable = () => {
        /* @intention: To ease the transition of updates made to the CMS
         *             content-type. New fields were added to handle
         *             descrete values and in order to preserve existing
         *             entries (and eliminate broken presentation), the
         *             old fields were "hidden" but the vaules will remain.
         * @return: {obj} - new field values || old field values.
         */
        const obj = {
            width: `${banner.dimensions?.width}${unit}`,
            height: `${banner.dimensions?.height}${unit}`,
            backgroundSize: `${banner.dimensions?.backgroundSize}${unit}`,
        };
        if (banner.dimensions?.width_x) {
            obj.width = banner.dimensions.width_x;
        }
        if (banner.dimensions?.height_y) {
            obj.height = banner.dimensions.height_y;
        }
        if (banner.dimensions?.max_width) {
            obj.maxWidth = banner.dimensions?.max_width;
        }
        if (banner.dimensions?.background_size) {
            obj.backgroundSize = `${banner.dimensions?.background_size} 100%`;
        }
        return obj;
    };

    const bannerStyle = useNewDimensionsIfAvailable();
    bannerStyle.backgroundImage = panel?.image?.url ? `url(${panel?.image.url}?auto=webp)` : '';
    const classes = useStyles({ banner, bannerStyle, panel });

    const trackClickThrough = useClickStreamCustomEventsTracking({
        salesforceResponse,
        isFireImpression: !!salesforceResponse?.content,
        page: { type: 'home' },
    });

    const handleClick = (e) => {
        if (panel.linking?.link?.title === 'Celebrations Passport'
            // VARIANT A is "Passport Enrolment Page"
            // CONTROL is "Passport Side Bar"
            && featureFlags['which-passport-sidebar-enabled-variant']?.variant === 'CONTROL') {
            e.preventDefault();
            passportOpenSideBar();
        }
        trackEvent({
            eventCategory: panel.linking?.tracking_event_category || '',
            eventAction: panel.linking?.tracking_event_action || '',
            eventLabel: panel.linking?.tracking_event_label || '',
        });
        trackClickThrough();
    };
    const PanelContainerRender = () => (
        <div className={`${classes.contentSection}  ${presentationFamily === 'flower' ? classes.flowerGenericBanner : ''}`}>
            {featureFlags['is-atlas-maintenance-alert-enabled'] && (
                <OutageMessage />
            )}
            {/* prefetch image used as BG */}
            <img src={`${panel?.image?.url}?quality=100&auto=webp`} alt={normalizeAltCopy(panel.image?.alt || 'background image')} className={classes.invisibleImage} />
            <Panel2 className={classes.headline} data={panelData} styleData={panel?.message_styling} />
        </div>
    );
    const desktopCallClickable = () => (
        panel?.linking?.link?.href?.includes('#disclaimer') ? (
            <a
                data-ga-category={panel.linking?.tracking_event_category || ''}
                data-ga-action={panel.linking?.tracking_event_action || ''}
                data-ga-label={panel.linking?.tracking_event_label || ''}
                tabIndex="0"
                onClick={(e) => {
                    handleClick(e);
                }}
                href={panel?.linking?.link?.href}
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...openInNewTab(panel.linking?.link?.href)}
                title={panel?.linking?.link?.title}
                className={presentationFamily === 'flower' ? classes.root : `${classes.root} ${classes.genericBannerSize}`}
                data-testid="genericbanner_block"
            >
                {PanelContainerRender()}
            </a>
        )
            : (
                <Link
                    data-ga-category={panel.linking?.tracking_event_category || ''}
                    data-ga-action={panel.linking?.tracking_event_action || ''}
                    data-ga-label={panel.linking?.tracking_event_label || ''}
                    tabIndex="0"
                    onClick={(e) => {
                        handleClick(e);
                    }}
                    to={{ pathname: panel?.linking?.link?.href }}
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    {...openInNewTab(panel.linking?.link?.href)}
                    title={panel?.linking?.link?.title}
                    className={presentationFamily === 'flower' ? `${classes.root} ${classes.flowerGenericBanner}` : `${classes.root} ${classes.genricBannerSize}`}
                    data-testid="genericbanner_block"
                    ariaLabel={data?.title}
                >
                    {PanelContainerRender()}
                </Link>
            )
    );

    if (panel?.image?.content_type === 'video/mp4') {
        return (
            <div className={classes.contentSectionVideo} style={{ ...processCss(panel.container_attributes, true) }}>
                {featureFlags['is-atlas-maintenance-alert-enabled'] && (
                    <OutageMessage />
                )}
                <video autoPlay loop muted width="100%" height="100%" controls controlsList="nodownload nofullscreen" disablePictureInPicture>
                    <source src={panel?.image?.url} type="video/mp4" preload="metadata" />
                    <track kind="captions" />
                </video>
                <div className={classes.headlineVideo}>
                    <Panel2 data={panelData} styleData={panel?.message_styling} />
                </div>
            </div>
        );
    }

    return (
        <>
            {panel?.banner_clickable ? (
                desktopCallClickable()
            )
                : (
                    <div className={classes.contentSection} style={{ ...processCss(panel.container_attributes, true), ...bannerStyle }}>
                        {featureFlags['is-atlas-maintenance-alert-enabled'] && (
                            <OutageMessage />
                        )}
                        <Panel2 className={classes.headline} data={panelData} styleData={panel?.message_styling} />
                    </div>
                )}
        </>
    );
};

GenericBanner.propTypes = {
    data: object.isRequired,
    trackEvent: func.isRequired,
    featureFlags: object.isRequired,
    passportOpenSideBar: func,
    presentationFamily: string.isRequired,
    salesforceResponse: object,
    setShowSkeleton: func.isRequired,
};

GenericBanner.defaultProps = {
    passportOpenSideBar: () => { },
    salesforceResponse: {},
};

const mapStateToProps = (state) => ({
    featureFlags: getFeatureFlags(state),
    presentationFamily: getPresentationFamily(state),
});

const mapDispatchToProps = (dispatch) => ({
    trackEvent: bindActionCreators(track, dispatch),
    passportOpenSideBar: bindActionCreators(openPassportSideBar, dispatch),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(GenericBanner);
